<template>
	<div class="container">
		<div class="login-dex1">
			<ul class="c-reg-panel">
				<li>
					<div class="c-reg-panel-leftbox">
						<van-row>
							<van-col span="20"><input type="text" @input="checkForm" v-model="inputValue" :placeholder="$t('login.PleaseEnterYourMobilePhoneNumber')" /></van-col>
						</van-row>
					</div>
				</li>
				<li>
					<div class="c-reg-panel-leftbox">
						<van-row>
							<van-col span="20"><input type="password" @input="checkForm" v-model="inputPassword" :placeholder="$t('login.PleaseInputAPassword')" /></van-col>
						</van-row>
					</div>
				</li>
			</ul>
		</div>

		<!-- <div class="wjmm">
			<span @click="routerToZhuCe()">{{ $t('login.RegisteredAccountNumber') }}</span> -->
			<!-- <span @click="routerToFortePaw()">{{ $t("login.ForgetThePassword") }}</span> -->
		<!-- </div> -->

		<button class="loginButtonQ" :class="haveCompleted ? 'mainBackColor1' : 'disabled'" :disabled="!haveCompleted" @click="doLogin" style="margin-bottom: 0.4rem;">{{ $t('login.login') }}</button>
	</div>
</template>
<script>
export default {
	name: 'loginPhone',
	data() {
		return {
			inputValue: '',
			inputPassword: '',
			haveCompleted: false,
		};
	},
	methods: {
		// 忘记密码
		// routerToFortePaw() {
		// 	this.$router.push('/tesetPassword');
		// },
		// 前往注册页面
		// routerToZhuCe() {
		// 	this.$router.push('/registerPage');
		// },

		// 登录按钮是否可用
		checkForm() {
			if (this.inputValue.trim() !== '' && this.inputPassword !== '') {
				this.haveCompleted = true;
			} else {
				this.haveCompleted = false;
			}
		},

		// 账号密码登录
		doLogin() {
			let toast=this.$toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t('system.Loading'),
			});
			this.$post2('user/api/passport/loginwithpwd', {
				username: this.inputValue,
				password: this.inputPassword,
				type: 'email',
			})
				.then(res => {
					toast.clear();
					// console.log(res);
					this.$saveLoginSession(res);
					this.$router.push({
						path: '/newHome',
						query: {
							ifhave: false
						}
					});
				})
				.catch(e => {
					toast.clear();
					// console.log(e, "出错");
					// this.showAlert(e);
					this.$toast.fail(this.$t('login.login_false'));
				});
		}
	}
};
</script>

<style lang="less">
.wjmm {
	display: flex;
	justify-content: flex-end;
	margin-top: 0.26rem;

	span {
		font-size: 0.24rem;
		// font-family: PingFang SC;
		color: #999999;
	}
}
.login-dex1 {
	margin-top: 0.55rem;
}
</style>
